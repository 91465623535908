<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('Menus')" />
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <MenuFilterForm :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template slot="item.enabled" slot-scope="{ item }">
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import MenuFilterForm from "../../components/menu/Filter";

export default {
  name: "MenuList",
  servicePrefix: "Menu",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    MenuFilterForm,
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("name"), value: "name" },
        { text: this.$i18n.t("enabled"), value: "enabled" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      selected: [],
      filters: {
        menu: {},
      },
      showMenuDialog: false,
      menuItem: {},
      showMenuDialogForm: false,
      menuFormItem: {},
      menuDialogTitle: this.$t("AddMenu"),
    };
  },
  computed: {
    ...mapGetters("menu", {
      items: "list",
    }),
    ...mapFields("menu", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("menu", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearMenuData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    menuDialogControl(close = false) {
      this.showMenuDialogForm = !close;
    },
    handleCreated(item) {
      console.log(item);
    },
    handleUpdated(item) {
      console.log(item);
    },
    createOrEditMenuHandler(item) {
      this.menuFormItem = item ?? {};
      if (item) {
        this.menuDialogTitle = this.$t("EditMenu");
      } else {
        this.menuDialogTitle = this.$t("AddMenu");
      }
      this.menuDialogControl();
    },
    refreshData(){
      this.clearMenuData();
      this.onSendFilter();
    }
  },
};
</script>
